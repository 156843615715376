import React, { useState } from 'react';
import CardMedia from '@material-ui/core/CardMedia';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import MUILink from '@material-ui/core/Link';
import Skeleton from '@material-ui/lab/Skeleton';
import Paper from '@material-ui/core/Paper'
import { Typography } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles((theme) => ({

    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    cardHeader: {
        padding: '12px',
    },
    avatar: {
        // backgroundColor: '#33b3e3',
    },
    cardHeaderTitle: {
        fontWeight: 500,
    },
    subheader: {
        color: theme.palette.grey[500],
    },
    action: {
        height: '100%'
    }
}));

export default function ModelCard(props) {
    const classes = useStyles();

    if (typeof window !== "undefined") { //client only hack
        const urlParams = new URLSearchParams(window.location.search);
    }

    if (props.skeleton) {
        return (<Card className={classes.card}>
            <CardMedia>
                <Skeleton style={{ paddingTop: '59%', animationDelay: props.id * 0.1 + 's' }} variant="rect" height={100} />
            </CardMedia>
            <CardHeader
                title={<Skeleton animation="wave" height={10} width="80%" style={{ marginBottom: 6 }} />}
                subheader={<Skeleton animation="wave" height={10} width="40%" />}
                classes={{
                    root: classes.cardHeader,
                    title: classes.cardHeaderTitle,
                }}
                avatar={
                    <Skeleton animation="wave" width={40} height={40} variant='circle' />
                }
            />
        </Card>)
    }

    const [loading, setLoading] = React.useState(true);

    const [imagesrc, setImageSRC] = React.useState(props.previewUrl);

    const onImageError = () => { setImageSRC('/img/placeholder.png') }

    const imgEl = React.useRef();

    const affiliate = props.affiliate;

    const imageLoaded = () => {
        setLoading(false);
        imgEl.current.removeAttribute('onLoad');
        imgEl.current.removeAttribute('onError');
    }

    React.useEffect(() => {
        if (imgEl.current.complete) imageLoaded();
    }, [imgEl.current]);

    //prefecth route on hover
    let preloaded = false;
    React.useEffect(() => {
        if (props.preload && !affiliate && imgEl.current) {
            imgEl.current.addEventListener('mouseenter', () => {
                if (preloaded) return;
                preloaded = true;
                props.preload(props.url);
            });
        }
        return () => {
            if (imgEl.current) {
                imgEl.current.removeEventListener('mouseenter', () => {
                    props.preload(props.url);
                });
            }
        }
    }, []);

    const CardThumb =
        <CardMedia
            title={props.alt}
            loading="lazy"
            alt={props.title}
        >
            <div>
                {loading && <Skeleton style={{ paddingTop: '59%', animationDelay: props.id * 0.1 + 's' }} variant="rect" height={100} />}
                <img
                    ref={imgEl}
                    onLoad={imageLoaded}
                    onClick={props.onClick}
                    onAuxClick={props.onClick}
                    onError={onImageError}
                    src={imagesrc}
                    className={`w-full anim-fadein ${loading ? 'hidden' : ''}`}
                    alt={props.title}
                />
            </div>
        </CardMedia>

    let discount = "";
    if (props.affiliate?.salePrice) {
        const originalPrice = parseInt(props.price.substring(1)); //server sends og price with $ in string
        discount = 100 - ((props.affiliate.salePrice / originalPrice).toFixed(2) * 100);
        discount = Math.ceil(discount / 5) * 5; //round to nearest 5
    }

    //Sort out action
    let action = null;
    if (affiliate) {
        action = (
            <div className="flex flex-col items-end justify-center h-full gap-1">
                <div className='flex'>
                    <div className={`${props.affiliate.salePrice ? '!line-through !text-gray-light' : '!bg-gray-dark'} rounded-md p-1 !ml-1 px-2`}>{props.price}</div>
                    {props.affiliate.salePrice && <div className='!bg-red-main rounded-md-left text-white-light p-1  px-2'>-{discount}%</div>}
                    {props.affiliate.salePrice && <div className='!bg-gray-dark rounded-md-right p-1 px-3'>${props.affiliate.salePrice}</div>}
                </div>
            </div>
        )
    }
    else if (props.licence) {
        action = (
            <div className="flex flex-col items-end justify-center h-full gap-1">
                <Chip label={props.licence.split(' ')[0]} className="!text-[#C1C1C1] !font-mono uppercase " size="small" />
            </div>
        )
    }

    return (
        <Card className={classes.card}
        >
            {
                affiliate ?
                    <a href={props.url} target="_blank" rel="noopener">{CardThumb}</a> :
                    <Link to={props.url}>{CardThumb}</Link>
            }

            <CardHeader
                classes={{
                    root: '!p-3 !mt-[-5px]',
                    title: classes.cardHeaderTitle,
                    action: 'h-full !m-0',
                }}
                avatar={
                    props.noAvatar ? <div></div> : <Avatar aria-label="dp" src={props.creator.DPURL} className={classes.avatar}>{props.creator.initials}</Avatar>
                }
                title={
                    affiliate ?
                        (<div className='flex justify-between items-center '>
                            <MUILink color="textPrimary" href={props.url} target="_blank" rel="noopener" >{props.title}</MUILink>
                        </div>) :
                        <MUILink color="textPrimary" component={Link} to={props.url} onClick={props.onClick} onAuxClick={props.onClick}>{props.title}</MUILink>
                }
                subheader={
                    affiliate ?
                        <MUILink className={classes.subheader} href={props.creator.profileLink} target="_blank" rel="noopener">{props.creator.username}</MUILink> :
                        <MUILink className={classes.subheader} to={props.creator.profileLink} component={Link}>{props.creator.username}</MUILink>
                }
                action={action}
            />
        </Card>

    )
}