import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

interface SimplePopupProps {
    handleClose?: { (event: {}, reason: "backdropClick" | "escapeKeyDown"): void},
    open: boolean,
    closeText?: String,
    title: String,
    body: React.ReactChild | React.ReactFragment,
    action?: React.MouseEventHandler<HTMLButtonElement>,
    actionText?: String,
}

export default function SimplePopup(props: SimplePopupProps) {
    return (
        <Dialog
            open={props.open}
            onClose={props.handleClose}
        >
            <DialogTitle>{props.title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {props.body}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                {props.action &&
                    <Button
                        onClick={props.action}
                        color="primary">
                        {props.actionText}</Button>}
                <Button onClick={props.handleClose} color="textPrimary">
                    {props.closeText}
                </Button>
            </DialogActions>
        </Dialog>
    );
}