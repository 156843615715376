import React, { useEffect } from 'react';
import axios from 'axios'
import Grid from '@material-ui/core/Grid';
import CssBaseline from '@material-ui/core/CssBaseline';
import PrimarySearchAppBar from '../components/Appbar';
import Helmet from 'react-helmet';
import detailStyles from './styles/detailStyle';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import FormControl from '@material-ui/core/FormControl';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel from '../components/TabPanel';
import SubjectIcon from '@material-ui/icons/Subject';
import PaletteIcon from '@material-ui/icons/Palette';
import PhotoFilterIcon from '@material-ui/icons/PhotoFilter';
import EditAppBar from '../components/EditAppbar'
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { useSnackbar } from 'notistack';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import DeleteIcon from '@material-ui/icons/Delete';
import ChipBowl from '../components/ChipBowl'
import { useForm } from 'react-hook-form';
import BGBox from '../components/BGBox'
import BGColors from '../util/BGColors';
import ConvertToHSL from '../util/RGB2HSL';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import DisplayAxiosError from '../util/DisplayAxiosError';
import { Catagories, style, Licences } from '../util/HardCodedLists';
import CreateGradientString from '../util/CreateGradientString';
import DeleteDialog from '../components/DeleteDialog';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import ModelViewerSetup from '../util/modelViewerInit';


const useStyles = makeStyles((theme) => ({
    noScroll: {
        margin: 0,
        height: '100%',
        overflow: 'hidden',
    },
    tab: {
        minWidth: 100,
    },
    tabRoot: {
        background: '#3c4043',
    },
    tabPanel: {
        width: '100%',
        height: '100%',
    },
    formControl: {
        width: '100%',
    },
    inputMargin: {
        marginBottom: '20px',
    }
}));

var bgData = {}; //the actual data sent to the server
const alphaNumeric = /^[\w\-\s]+$/;

const Edit = (props) => {
    const modelClasses = detailStyles(props);
    const classes = useStyles();

    //hookform
    const { register, handleSubmit, watch, errors, clearErrors } = useForm({ mode: 'onChange', shouldUnregister: false });

    //Tab stuff idk
    const [tabValue, setTabValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setTabValue(newValue);
    };

    //info snackbar
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const snaction = key => (
        <Button
            style={{ color: 'white' }}
            onClick={() => {
                localStorage.setItem('doneEditTutorial', true);
                closeSnackbar(key);
            }}
        >Got it</Button>
    );

    //input needs to be in state ffs
    const [modelTitleValue, setModelTitle] = React.useState(props.model.Title);
    const [modelDescValue, setModelDesc] = React.useState(props.model.Description);

    //Dropdown
    // const [modelStyle, setStyle] = React.useState(props.model.Style ? style.findIndex((e) => e === props.model.Style) : '');
    const [cat, setCat] = React.useState(props.model.Category ? Catagories.findIndex((e) => e === props.model.Category) : '');

    //Chips
    const [chipData, setChipData] = React.useState(props.model.Tags.map((tag) => { return { key: Math.random(), label: tag } }));
    const tagInput = React.createRef();

    const [dialogState, setDialogState] = React.useState({ open: false, action: null });

    const maxTagLength = 20;
    const handleChipInput = (event) => {
        if (!event.key.match(alphaNumeric)) {
            event.preventDefault();
        }

        if(event.target.value.match(/Low\s?Poly/i)) {
            enqueueSnackbar("Everything on the site is low poly dude...", {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right',
                },
            });
            return;
        }

        if (event.key === 'Enter' && chipData.length < 8 && event.target.value.length >= 2) {
            setChipData([...chipData, { key: Math.random(), label: event.target.value }]);
            event.target.value = "";
        }
        else if (event.key === 'Enter' && chipData.length >= 8) {
            enqueueSnackbar("Too many tags! Try removing one", {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right',
                },
            });
        }

        if (event.target.value.length > maxTagLength) {
            event.preventDefault();
            event.target.value = event.target.value.slice(0, maxTagLength + 1);
        }
    }

    //BG
    const [modelBG, setModelBG] = React.useState(CreateGradientString(props.model.Background.one, props.model.Background.two, props.model.Background.type));
    const [Vignette, setVignette] = React.useState(true);
    const [GradientType, setGradientType] = React.useState('Radial');

    const storeBGData = (one, two, type) => {
        bgData = { color: { one, two }, type };
    }

    let BgColorsPanel = Object.keys(BGColors).map(function (key) {
        return (
            <React.Fragment key={key}>
                <h4>{key}</h4>
                <Grid container spacing={0} style={{ borderRadius: '5px', }}>
                    {
                        BGColors[key].map((staticCol, index) => {
                            var color = { ...staticCol };
                            if (Vignette && !color.two) { //set second color for solids if vin is enabled
                                var col = ConvertToHSL(color.one);
                                col.l = '15%';
                                color.two = `hsl(${col.h}, ${col.s}, ${col.l})`;
                            }
                            return (
                                <Grid item key={index}>
                                    <BGBox key={index} gradient={GradientType}
                                        color1={color.one}
                                        color2={color.two}
                                        setBG={setModelBG}
                                        storeBG={storeBGData}
                                    />
                                </Grid>
                            )
                        })}
                </Grid>
            </React.Fragment>
        )
    });

    //submit //todo submit bg colors
    const onSubmit = data => {
        //Shitty dook validation
        //if (modelStyle === '') {
        //    enqueueSnackbar("Model style category wasn't set", { variant: 'error' });
        //    return;
        //}
        data.ModelStyle = 0;

        if (cat === '') {
            enqueueSnackbar("Category wasn't set", { variant: 'error' });
            return;
        }
        data.Category = cat;

        if (chipData.length < 1) {
            enqueueSnackbar("Must add at least 1 tag", { variant: 'error' });
            return;
        }
        data.Tags = chipData;

        if (Object.keys(bgData).length === 0 && Object.keys(props.model.Background).length === 0) { //make sure a background is set on first upload
            enqueueSnackbar("No background was set", { variant: 'error' });
            return;
        }

        data.BGData = bgData;
        data.Orbit = document.querySelector('model-viewer#mainModel').getCameraOrbit();

        closeSnackbar('peepeepoopoo');

        axios.post(`/api/model/${props.model.PublicID}/edit`, { data, }, { withCredentials: true, credentials: 'include' })
            .then(function (response) {
                props.history.push('/m/' + props.model.PublicID, { from: 'edit' });
            }).catch(error => DisplayAxiosError(error, enqueueSnackbar));
    }

    async function deleteModel() {
        //grecaptcha.ready(function () {
        //grecaptcha.execute('6Lc_LAcaAAAAAJ8RT1-vgmA-GsTDH0KJBZ_toZZV', { action: 'deleteModel' }).then(async function (token) {
        const config = {
            withCredentials: true,
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            //data: { token }
        };
        await axios.delete(`/api/model/${props.model.PublicID}/`, config).then(function (response) {
            props.history.push({
                pathname: '/',
                state: { message: 'Your model was deleted' }
            })
        }).catch(error => DisplayAxiosError(error, enqueueSnackbar));
        //})
        //});
    }

    // async function AutoFillTags(title) {
    //     if (!title) enqueueSnackbar("Please add a title first", { variant: 'error' });
    //     var resp = await axios.get(`https://api.datamuse.com/words?ml=${title}`);
    //     var tags = [];
    //     for (let i = 0; i < resp.data.length; i++) {
    //         const word = resp.data[i];
    //         if (!word.tags || !word.tags.includes("n")) continue; //exclude words that arn't nouns (word missing tag somehow)

    //         if (tags.length < 5)
    //             tags.push({ key: Math.random(), label: word.word });
    //         else
    //             break;
    //     }
    //     setChipData(tags);
    // }


    React.useEffect(() => {
        if (typeof window !== "undefined" && !localStorage.getItem('doneEditTutorial')) {
            enqueueSnackbar("The rotation and zoom of the model here will be used for the thumbnail", {
                persist: true,
                key: "peepeepoopoo",
                action: snaction,
                variant: 'info',
                position: 'center',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'center',
                },
            });
        }
        ModelViewerSetup(false)
    }, []);

    return (
        <div className={classes.noScroll}>
            <CssBaseline />
            <Helmet>
                <title>{`Edit - ${props.model.Title}`}</title>
                {/* <script src="https://www.google.com/recaptcha/api.js?render=6Lc_LAcaAAAAAJ8RT1-vgmA-GsTDH0KJBZ_toZZV"></script> */}
            </Helmet>
            <EditAppBar history={props.history} onSave={handleSubmit(onSubmit)} title={"Edit Model"}></EditAppBar>
            <Grid container direction="row">
                <Grid container direction="column" item lg={10} >
                    {/*Model Viewer*/}
                    <Grid item style={{ height: `96vh` }}>
                        <model-viewer
                            oncontextmenu="return false;"
                            id="mainModel"
                            environment-image="/static/HDR/BG.hdr"
                            class={`${modelClasses.model} noMenu`}
                            src={`https://static.poly.pizza/${props.model.ResourceID}.glb`}
                            alt={props.model.Title}
                            camera-controls
                            shadow-intensity="1"
                            interaction-prompt="none"
                            loading="eager"
                            style={{ background: modelBG, transition: '1s' }}
                            camera-orbit={props.model.Orbit ? `${props.model.Orbit.theta}rad ${props.model.Orbit.phi}rad ${props.model.Orbit.radius}m` : '0deg 75deg 105%'}
                        />
                    </Grid>
                </Grid>
                <Grid container item direction="row" justify="center" item lg={2}>
                    <div className={classes.tabPanel}>
                        <AppBar position="static" className={classes.tabRoot} >
                            <Tabs
                                value={tabValue}
                                onChange={handleChange}
                                centered
                                indicatorColor="primary"
                                textColor="primary"

                            >
                                <Tab classes={{ root: classes.tab }} icon={<SubjectIcon />} aria-label="Details" />
                                <Tab classes={{ root: classes.tab }} icon={<PaletteIcon />} aria-label="Background color" />
                                {/* <Tab classes={{ root: classes.tab }} icon={<PhotoFilterIcon />} aria-label="Filters" /> */}
                            </Tabs>
                        </AppBar>

                        {/*First tab - Description */}
                        <TabPanel value={tabValue} index={0} className={classes.tabPanel}>
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <h3 style={{ marginTop: 0 }}>Details</h3>
                                <TextField
                                    label="Title"
                                    name="Title"
                                    className={classes.inputMargin}
                                    fullWidth
                                    variant="outlined"
                                    defaultValue={modelTitleValue}
                                    onChange={(e) => setModelTitle(e.target.value)}
                                    error={errors.Title !== undefined}
                                    helperText={errors.Title?.message}
                                    inputRef={register({
                                        required: 'Required', maxLength: {
                                            value: 30,
                                            message: `Title is too long`
                                        }
                                    })}
                                />

                                <TextField
                                    className={classes.inputMargin}
                                    label="Description"
                                    name="Description"
                                    placeholder="Describe the model + plug your stuff!"
                                    fullWidth
                                    multiline
                                    variant="outlined"
                                    defaultValue={modelDescValue}
                                    onChange={(e) => setModelDesc(e.target.value)}
                                    minRows={4}
                                    maxRows={12}
                                    error={errors.Description !== undefined}
                                    helperText={errors.Description?.message}
                                    inputRef={register({
                                        required: 'Required',
                                        maxLength: {
                                            value: 300,
                                            message: `Description is too long (ain't no body got time to read that)`
                                        }
                                    })}
                                />
    
                                <FormControl className={`${classes.inputMargin}`} fullWidth>
                                    <InputLabel>Category</InputLabel>
                                    <Select
                                        placeholder="Category"
                                        name="Category"
                                        id="catInput"
                                        label="Category"
                                        value={cat}
                                        fullWidth
                                        error={errors.Category !== undefined}
                                        helperText={errors.Category?.message}
                                        //inputRef={register({ required: 'Required' })}
                                        onChange={(e) => setCat(e.target.value)}
                                        inputProps={{
                                            inputRef: (ref) => {
                                                if (!ref) return;
                                                register({
                                                    name: "Category",
                                                    value: ref.value,
                                                    validate: value => value === '' || 'Required',
                                                });
                                            },
                                        }}
                                    >
                                        {Catagories.map((item, index) => {
                                            if (item === 'Other') index = 999;
                                            return <MenuItem key={index} value={index}>{item}</MenuItem>
                                        })}
                                    </Select>
                                </FormControl>

                                <TextField
                                    className={classes.inputMargin}
                                    label="Add a search tag"
                                    fullWidth
                                    helperText="Enter to add a tag, must add at least 1 tag. Max 8 tags. Title and tags are used for search, so try to include words that you might use when searching for this model. Tags/Titles that abuse this system will be removed."
                                    onKeyPress={handleChipInput}
                                    inputRef={(e) => { //https://react-hook-form.com/faqs/#Howtosharerefusage
                                        register(e, {})
                                        tagInput.current = e
                                    }} />

                                <ChipBowl chips={chipData} setChips={setChipData} />

                                <Button variant="contained" startIcon={<DeleteIcon />}
                                    fullWidth color="secondary" onClick={() => setDialogState({ open: true, action: deleteModel })}>Delete</Button>
                            </form>
                        </TabPanel>
                        <TabPanel value={tabValue} index={1}>
                            <h3 style={{ marginTop: 0 }}>Background</h3>
                            <h4>Settings</h4>
                            <FormControlLabel
                                label="Vignette"
                                control={
                                    <Switch
                                        checked={Vignette}
                                        color="primary"
                                        onChange={() => { setVignette(!Vignette) }}
                                        name="checkedA"

                                    />}

                            />
                            <FormControlLabel
                                //label="Radial"
                                label={GradientType}
                                control={
                                    <Switch
                                        checked={GradientType === 'Radial'}
                                        color="primary"
                                        onChange={() => { setGradientType(GradientType === 'Radial' ? 'Linear' : 'Radial') }}
                                        name="checkedA" />}

                            />
                            {BgColorsPanel}
                            <Typography variant="body2" color="textSecondary" align="center" style={{ marginTop: '20px' }}>Tip: Try to avoid backgrounds with low contrast</Typography>
                        </TabPanel>
                        {/* <TabPanel value={tabValue} index={2}>
                            <h3 style={{ marginTop: 0 }}>Filters</h3>
                            <p style={{ fontSize: '7px' }}>man i can't be fucked implementing this right now...</p>
                            <p style={{ fontSize: '7px' }}>wait i mean uh</p>
                            <h4>Coming soon!</h4>
                        </TabPanel> */}
                    </div>
                </Grid>
            </Grid>
            <DeleteDialog
                state={dialogState}
                setState={setDialogState}
                title={"Delete model?"}
                content={"Are you sure you want to delete this model? This can't be undone."}
            />
        </div>
    );
}


Edit.getInitialProps = async ({ req, res, match, history, location, ...ctx }) => {
    var isLoggedIn = req !== undefined ? ctx.authed : authed; 
    if (!isLoggedIn) return { statusCode: 404 }

    //catch errors
    let statusCode;
    const axiosError = (e) => {
        if (e.response && e.response.status) {
            statusCode = e.response.status
        }
    };

    //Check ownership
    const ROOT_URL = `${process.env.SITE_ROOT}/api/model/` + match.params.id + '/details/';
    let response;
    if (req !== undefined && req.headers.cookie) {
        response = await axios.get(ROOT_URL + 'interactions', { headers: { cookie: req.headers.cookie } }).catch(axiosError); //send through cookies from original request
    }
    else {
        response = await axios.get(ROOT_URL + 'interactions', { withCredentials: true }).catch(axiosError);
    }

    //Check if we're allowed to edit + no errors
    if (statusCode !== undefined) return { statusCode };
    if (!response.data.CanEdit) return { statusCode: 404 }

    //Actually get deets
    const resp = await axios.get(ROOT_URL);
    const model = resp.data;

    return { model };
}

export default Edit;