import { Avatar, Button, CircularProgress, Collapse, TextField, Typography } from '@material-ui/core';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import Comment from '../components/Comment';
import DeleteDialog from './DeleteDialog';
import SendIcon from '@material-ui/icons/Send';
import ClearIcon from '@material-ui/icons/Clear';

var replyID = null; //comment reply
var submittingComment = false;

let localUser: { PublicID: string; DPURL: string; };

export default function CommentBox(props: { rootID: string; isLoggedIn: any; showCount?: boolean; visible?: boolean; className?: string; placeHolderText?: string; }) {

    const endpoints = {
        create: `/api/comment/${props.rootID}`,
        getAll: `/api/comment/${props.rootID}`,
        delete: `/api/comment/${props.rootID}`, //needs commend id at end
    }

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const { register, handleSubmit, watch, errors, clearErrors } = useForm({ mode: 'onChange' });

    const [replyingTo, setReplyingTo] = useState("");
    const [commentButtonsState, setCommentButtonsOpen] = React.useState(false);
    const [commentsState, setComments] = React.useState([]); //comment lazy loading data
    //Comment delete confirm dialog
    const [dialogState, setDialogState] = React.useState({ open: false, action: null });

    const commentInput = useRef<HTMLInputElement>();

    const [commentsLoaded, setcommentsLoaded] = useState(false);

    useEffect(() => { localUser = JSON.parse(localStorage.getItem('user')); }, [])

    //Crete comment
    function onSubmit(data) {
        if (submittingComment) return;

        submittingComment = true;
        data.replyid = replyID;
        grecaptcha.ready(function () {
            grecaptcha.execute('6Lc_LAcaAAAAAJ8RT1-vgmA-GsTDH0KJBZ_toZZV', { action: 'comment' }).then(async function (token) {
                await axios.post(endpoints.create, { data, token, }).then(function (response) {
                    //@ts-ignore
                    document.getElementById("commentBox").reset();
                    setReplyingTo("");
                    enqueueSnackbar("Your comment was posted!", {
                        variant: 'success',
                    });
                    //setComments(null); //hard refresh comment data after post
                    GetComments(`/api/comment/${props.rootID}`);
                    setCommentButtonsOpen(false);
                    submittingComment = false;
                    replyID = null;

                }).catch((error) => {
                    submittingComment = false;
                    if (error.response) {
                        enqueueSnackbar(error.response.data.error, {
                            variant: 'error',
                        });
                    }
                    else {
                        enqueueSnackbar("Something went wrong with the server, try again in a bit", {
                            variant: 'error',
                        });
                    }
                })
            });
        });
    };

    function GetComments(endPoint) {
        axios.get(endPoint)
            .then((response) => {
                //unwrap comment replies
                let comments = [];
                for (let i = 0; i < response.data.length; i++) {
                    const comment = response.data[i];
                    comment.isReply = false;
                    comments.push(comment);

                    if (comment.Replies !== undefined) {
                        for (let j = 0; j < comment.Replies.length; j++) {
                            const reply = comment.Replies[j];
                            reply.isReply = true;
                            reply.parent = comment._id;
                            comments.push(reply);
                        }
                        delete comment.Replies;
                    }
                }
                setComments(comments);
                setcommentsLoaded(true);
            }).catch((error) => {
                console.log(error);
            });
    }

    function StartReply(id, name) {
        if (!props.isLoggedIn) {
            enqueueSnackbar("You need to be logged in to do that", { variant: 'info', });
        }
        else {
            replyID = id;
            setReplyingTo("Replying to " + name);
            setCommentButtonsOpen(true);
            commentInput.current.focus();
        }
    }

    function closeCommentBox() {
        setCommentButtonsOpen(false);
        replyID = null;
        clearErrors("comment");
        setReplyingTo("");
        commentInput.current.value = "";
    }

    //Lazy load comments when visible
    const commentLoaderRef = React.useRef();

    //Clear comments on new thing loaded
    useEffect(() => {
        setComments([]);
        setcommentsLoaded(false);
    }, [props.rootID]);

    //observe comment spinner to lazy load comments
    React.useEffect(() => {
        var observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting && !commentsLoaded) {
                    GetComments(`/api/comment/${props.rootID}`);
                }
            }, { root: null, rootMargin: "0px", threshold: 0 });

        if (commentLoaderRef.current) {
            observer.observe(commentLoaderRef.current);
        }

        return () => {
            if (commentLoaderRef.current) {
                observer.unobserve(commentLoaderRef.current);
            }
        }
    }, [props.visible, props.rootID, commentsLoaded]);

    //Hide instead of delete to keep state 
    if (props.visible === false) return null;

    //Loading spinner

    return (
        <>
            <div
                ref={commentLoaderRef}
                className={props.className + ' flex mt-5 h-[40vh] w-full justify-center ' + (commentsLoaded ? 'hidden' : '')}>
                <CircularProgress />
            </div>
            <div className={props.className + ' w-full'}>
                {props.showCount && <Typography variant="h6" className="">{commentsState.length} Comments</Typography>}

                {/* Comment Entry */}
                <form id="commentBox" method="post" onSubmit={handleSubmit(onSubmit)}>
                    <div className='flex items-center justify-center flex-grow gap-4 pb-4'>
                        <Avatar
                            alt={localUser ? localUser.PublicID : ''}
                            src={localUser ? localUser.DPURL : ''}
                            className={''}
                        />
                        <TextField
                            type="text"
                            id="comment"
                            name="comment"
                            variant='outlined'
                            maxRows={5}
                            label={replyingTo}
                            error={errors.comment !== undefined && commentButtonsState}
                            helperText={commentButtonsState ? errors.comment?.message : ""}
                            className="flex-grow-1"
                            placeholder={props.placeHolderText ?? "Like this model? Let the creator know!"}
                            fullWidth multiline margin="normal"
                            onClick={() => setCommentButtonsOpen(true)}
                            inputRef={(e) => { //https://react-hook-form.com/faqs/#Howtosharerefusage
                                register(e, { required: 'Required', maxLength: { value: 280, message: 'Message is longer than 280 characters' } })
                                commentInput.current = e
                            }}
                            InputLabelProps={{
                                shrink: true,
                            }} />
                    </div>
                    <Collapse in={commentButtonsState}>
                        <div className='flex flex-row-reverse items-end flex-grow mt-3 gap-2'>
                            <Button size="large"
                                type="submit"
                                variant="contained"
                                color="primary"
                                className={'uppercase'}
                                startIcon={<SendIcon />}>Send</Button>

                            <Button size="large"
                                onClick={() => { closeCommentBox() }}
                                variant="text"
                                // color="textPrimary"
                                className={'uppercase'}
                                startIcon={<ClearIcon className={'text-[#80868b]'} />}>Cancel</Button>
                        </div>
                    </Collapse>
                </form>

                {/* Comment List */}
                {commentsLoaded && commentsState.map((comment) => (
                    <Comment
                        key={comment._id}
                        commentID={comment._id}
                        parent={comment.parent}
                        username={comment.Creator.username}
                        body={comment.Body}
                        dpSrc={comment.Creator.DPID} //fuggin populate won't return virtual fields so
                        score={comment.Score}
                        date={new Date(comment.Posted)}
                        onReply={StartReply}
                        reply={comment.isReply}
                        ownComment={comment.Creator.isMe}
                        enqueueSnackbar={enqueueSnackbar}
                        dialogState={dialogState}
                        setDialogState={setDialogState}
                        isLoggedIn={props.isLoggedIn}
                        initialVoteState={comment.votedOn}
                        endpoints={endpoints}
                    />
                ))}

                {/*Comment delete dialog*/}
                <DeleteDialog state={dialogState} setState={setDialogState} title={"Delete comment?"} content={"Are you sure you want to delete this comment? This can't be undone."} />
            </div>
        </>
    )
}