import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import MUILink from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from "@material-ui/core/Switch";
import { Link } from 'react-router-dom';
import { Divider } from '@material-ui/core';
import QuickSurvey from '../util/quickSurvey';
import { Licences } from '../util/HardCodedLists';
import { AdSlot } from '../models/adSlot';
import { getRandomInt } from '../util/dooktools';
import axios from 'axios';
import FavoriteIcon from '@material-ui/icons/Favorite';

const randomBase = Math.floor(Math.random() * 100);

function stoi(string) {
    let sum = 0;
    for (let i = 0; i < string.length; i++) {
        sum += string.charCodeAt(i);
    }
    return sum;
}

function getPrice(min, max, ID) {
    const num = stoi(ID) + randomBase;
    return num % (max - min) + min;
}

interface attPropTypes {
    model: { Title: {}; Creator: { Username: {}; }; PublicID: string; Licence: string; };
    open: boolean;
    handleClose: { (event: {}, reason: "backdropClick" | "escapeKeyDown"): void; (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void; };
    snackbar: any;
    adText: AdSlot;
    ToggleLike: void;
    LikeState: boolean;
}

//This project contains CC-BY models from Poly Pizza. Full credits here.
//This project contains free models from Poly Pizza licenced under CC-BY. Full credits here.

export default function AttributionPopup(props: attPropTypes) {

    const CC0linkAtt = (
        <span>
            <MUILink color="primary" href={`https://poly.pizza/m/${props.model.PublicID}`}>{props.model.Title}</MUILink> by&nbsp;
            <MUILink color="primary" href={`https://poly.pizza/u/${props.model.Creator.Username}`}>{props.model.Creator.Username}</MUILink>
        </span>)
    const CC0fullText = <span>{props.model.Title} by {props.model.Creator.Username} ({`https://poly.pizza/m/${props.model.PublicID}`})</span>

    const CCBYlinkAtt = (
        <span>
            <MUILink color="primary" href={`https://poly.pizza/m/${props.model.PublicID}`}>{props.model.Title}</MUILink> by&nbsp;
            <MUILink color="primary" href={`https://poly.pizza/u/${props.model.Creator.Username}`}>{props.model.Creator.Username}</MUILink>
            &nbsp;[<MUILink color="primary" href="https://creativecommons.org/licenses/by/3.0/">CC-BY</MUILink>] via Poly Pizza
        </span>
    )
    const CCBYfullText = <span>{props.model.Title} by {props.model.Creator.Username} [CC-BY] (https://creativecommons.org/licenses/by/3.0/) via Poly Pizza ({`https://poly.pizza/m/${props.model.PublicID}`})</span>

    const attributionRef = React.createRef();
    const [attText, setAttribuion] = React.useState({ element: CCBYlinkAtt, fulltext: false });
    const [surveyPrice, setsurveyPrice] = React.useState(0);
    const [surveyComplete, setSurveyComplete] = React.useState(false);



    const CC0 = props.model.Licence === Licences[1];

    const toggleText = () => {
        let state = attText.fulltext;
        state = !state;
        const ccbystate = state ? CCBYfullText : CCBYlinkAtt;
        const cc0state = state ? CC0fullText : CC0linkAtt;
        setAttribuion({ element: CC0 ? cc0state : ccbystate, fulltext: state });
    }

    React.useEffect(() => {
        const text = CC0 ? CC0linkAtt : CCBYlinkAtt
        setAttribuion({ element: text, fulltext: false });
        setsurveyPrice(getPrice(3, 5, props.model.PublicID));
        setSurveyComplete(false);
    }, [props.model.Title]);

    const [adIndex, setadIndex] = React.useState(0);
    let logClick = () => {
        axios.post(`/api/puppies/${props.adText.Slug}/Click`, { Variant: adIndex });
    };

    React.useEffect(() => {
        if (props.adText) {
            const index = getRandomInt(props.adText.Text.length);
            setadIndex(index);
            // console.log(props.adText.Text);
            // console.log(adIndex);


        }
    }, [props.adText]);


    //wat da fak
    //https://stackoverflow.com/questions/34191780/javascript-copy-string-to-clipboard-as-text-html
    function copyElementToClipboard(element, sb) {

        sb("Text copied!", { variant: 'success' })
        window?.plausible("Attributed", { props: { fullText: attText.fulltext } });

        window.getSelection().removeAllRanges();
        let range = document.createRange();
        range.selectNode(typeof element === 'string' ? document.getElementById(element) : element);
        window.getSelection().addRange(range);
        document.execCommand('copy');
        window.getSelection().removeAllRanges();
    }

    return (
        <Dialog
            open={props.open}
            onClose={props.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{!CC0 ? 'You must include attribution' : 'Lucky you!'}</DialogTitle>
            <DialogContent className="overflow-hidden">
                {
                    CC0 ?
                        <DialogContentText id="alert-dialog-description">
                            This model is Public domain, meaning you can use this model in any project (personal or commercial) without attribution. But if you're feeling nice, you can still credit the creator by including the text below in your project. <MUILink color="primary" href="https://creativecommons.org/publicdomain/zero/1.0/" target="_blank">Learn more</MUILink>
                        </DialogContentText> :
                        <DialogContentText id="alert-dialog-description">
                            This model is published under a CC-BY license. That means it can be used in any project (personal or commercial) so long as the text below is included. <MUILink color="primary" href="https://creativecommons.org/licenses/by/3.0/" target="_blank">Learn more</MUILink>
                        </DialogContentText>
                }

                <div className='p-7'>
                    <Typography variant="h6" style={{ textAlign: 'center' }}>
                        <span ref={attributionRef}>{attText.element}</span>
                    </Typography>
                </div>
                <FormControlLabel style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}
                    label="Full text links"
                    control={<Switch color="primary" checked={attText.fulltext} onChange={toggleText} />}
                />
                <div className='flex justify-between'>
                    <Button variant="contained" color="primary" onClick={props.ToggleLike} disabled={props.LikeState} startIcon={<FavoriteIcon />}>
                        Like This model
                    </Button>
                    <div className='flex gap-2'>
                        <Button variant="contained" color="primary" onClick={() => copyElementToClipboard(attributionRef.current, props.snackbar)}>
                            Copy
                        </Button>
                        <Button variant="outlined" onClick={props.handleClose} color="textPrimary">
                            ok
                        </Button>
                    </div>
                </div>

            </DialogContent>

            <Divider style={{ margin: '10px 20px 0px 20px' }} />

            {/* {!CC0 && survey} */}
            {props.adText &&
                <MUILink onClick={logClick} href={props.adText.Link} target="_blank" variant="body2" color="textPrimary"
                    style={{ textAlign: 'center', fontWeight: 500, paddingBottom: '10px', marginTop: '20px', textDecoration: 'underline' }}>
                    {props.adText.Text[adIndex] && props.adText.Text[adIndex].replace('__', ' - ')}
                </MUILink>}
        </Dialog>
    );
}

    // function answerSurvey(Answer : string) {
    //     /* @ts-ignore*/
    //     QuickSurvey({
    //         surveyID: 'proLicence',
    //         Object: props.model.PublicID,
    //         Variable: surveyPrice.toString(),
    //         Answer,
    //     });
    //     setSurveyComplete(true);

    //     /* @ts-ignore*/
    //     window.plausible?.('QuickSurvey', { props: { Title: props.model.Title, Price: surveyPrice, Answer, ID: 'proLicence' } });
    // }

    // const survey =
    //     !surveyComplete ?
    //         <React.Fragment>
    //             <Typography variant="body" color="textPrimary" style={{ textAlign: 'center', fontWeight: '600', paddingBottom: '10px', marginTop: '20px', textDecoration: 'none' }}>
    //                 Would you pay ${surveyPrice} to use this model without attribution?
    //             </Typography>
    //             <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px', }}>
    //                 <Button onClick={() => answerSurvey('Yes')} size="small" color="primary" variant="contained" style={{ textTransform: 'none', marginRight: '10px' }}>Yeah</Button>
    //                 <Button onClick={() => answerSurvey('No')} size="small" color="primary" variant="contained" style={{ textTransform: 'none' }}>Nah</Button>
    //             </div>
    //         </React.Fragment>
    //         :
    //         <Typography variant="body" style={{ textAlign: 'center', fontWeight: '600', paddingBottom: '10px', marginTop: '20px', textDecoration: 'none' }}>
    //             Thanks!
    //         </Typography>
