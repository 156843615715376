//Bootleg lodash
type ObjectType = { [key: string]: any };

export function pick(object: ObjectType, keys: string[]): any {
    const result: any = {};
    keys.forEach(key => {
        let keyPath = key.split('.');
        let value = object;
        for (let i = 0; i < keyPath.length; i++) {
            if (value != null && keyPath[i] in value) {
                value = value[keyPath[i]];
            } else {
                // Log an error or handle it as needed
                console.error(`[Bootdash] Key path "${keyPath.slice(0, i+1).join('.')}" not found.`);
                break;
            }
        }
        if (value !== undefined) {
            result[key] = value;
        }
    });
    return result;
}

export function isObject(value) {
    const type = typeof value;
    return value != null && (type === 'object' || type === 'function');
}


export function has(object: ObjectType, path: string | string[]): boolean {
    if (!path) return false; // Return false if path is falsy
    
    let pathArray: string[];
    
    if (typeof path === 'string') {
        pathArray = path.split('.');
    } else {
        pathArray = path;
    }

    let obj = object;

    for (let i = 0; i < pathArray.length; i++) {
        if (obj == null || obj[pathArray[i]] === undefined) { // Check for null or undefined
            return false;
        }
        obj = obj[pathArray[i]];
    }

    return true;
}
