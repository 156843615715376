import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import MLink from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useForm } from 'react-hook-form';
import isEmail from 'validator/lib/isEmail';
import SimplePopup from '../components/SimplePopup.tsx'
import Snackbar from '../components/Snackbar'
import LoadingBar from '../components/LoadingBar'
import Axios from 'axios';
import debouncePromise from 'awesome-debounce-promise';
import DisplayAxiosError from '../util/DisplayAxiosError'
import { Helmet } from 'react-helmet';
import {InputAdornment, IconButton } from "@material-ui/core";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";


const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  PrivacyPolicyLabel: {
    zIndex: 3,
  },
}));

const PrivacyPolicyLabel = (<span> I accept the <MLink href={'/docs/tos'} target="blank">Terms of Service</MLink> and <MLink href={'/docs/privacy'} target="blank" >Privacy Policy</MLink> </span>)
const popupBody = (
  <div>
    <p>
      These rules mean that after being <MLink href='https://www.theguardian.com/technology/2016/dec/15/passwords-hacking-hashing-salting-sha-2' target="_blank" rel="noreferrer">salted and hashed</MLink> your password will take at least <b>200 years</b> to be cracked in the event of a database breach.
  </p>
    <p>
      If these rules are too inconvenient for you, we recommend using a password manager.
    </p> {/* the sign in with google option or */}
  </div>
)

async function checkname(value) {
  var response = await Axios.get('/api/checkname/' + value);
  return response.data.canuse;
}

const SignUp = (props) => {
  const classes = useStyles();
  const { register, handleSubmit, watch, errors } = useForm({ mode: 'onChange' });

  const [popupIsOpen, setPopupOpen] = React.useState(false); //for 'why' text in password helper

  //Snackbar
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarStatus, setSnackbarStatus] = React.useState("error");

  function SnackBarAlert(message, status) {
    setSnackbarMessage(message);
    setSnackbarStatus(status);
    setSnackbarOpen(true);
  }

  //Loading bar
  const [loadingEnabled, setLoadingBar] = React.useState(false);

  //showpassword
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  //Stop form from submitting on enter
  const checkKeyDown = (e) => {
    if (e.key === 'Enter') e.preventDefault();
  };

  //Form Submit
  const onSubmit = data => {
    grecaptcha.ready(function () {
      //get ui ready
      setSnackbarOpen(false);
      setLoadingBar(true);

      grecaptcha.execute('6Lc_LAcaAAAAAJ8RT1-vgmA-GsTDH0KJBZ_toZZV', { action: 'signup' }).then(async function (token) {
        var response = await Axios.post("/api/makeuser", { data, token, })
          .then(function (response) {
            setLoadingBar(false);
            if (response.status === 201) {
              SnackBarAlert("Sweet! Check your email for next steps (make sure to check your spam folder too)", 'success');
            }
            else {
              SnackBarAlert("Something went wrong", 'warning');
            }
          })
          .catch(function (error) { //you fucked it 
            setLoadingBar(false);
            SnackBarAlert(error.response.data.error, 'warning');
          });
        console.log(response);
      })
    });
  }

  const passwordHelperText = (
    <span>Password must be at least 8 characters</span>
  )
  const handleDialogClose = () => { setPopupOpen(false); };

  return (
    <React.Fragment>
      <Helmet>
        <title>{`Poly Pizza - Sign up`}</title>
        <meta name="description" content={`Download thousands of low poly 3D models for free. No login required. Ready for AR/VR, Unity, Unreal or Godot.`} />
        {/* <script src="https://www.google.com/recaptcha/api.js?render=6Lc_LAcaAAAAAJ8RT1-vgmA-GsTDH0KJBZ_toZZV"></script> */}
      </Helmet>
      <LoadingBar enabled={loadingEnabled} />
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon/>
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign up
        </Typography>
          <form className={classes.form} onSubmit={handleSubmit(onSubmit)} onKeyDown={(e) => checkKeyDown(e)}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  autoComplete="username"
                  name="username"
                  variant="outlined"

                  fullWidth
                  id="username"
                  label="Username"
                  error={errors.username !== undefined}
                  helperText={errors.username?.message}
                  inputRef={register({
                    required: 'Required',
                    minLength: {
                      value: 2,
                      message: 'Username too short'
                    },
                    maxLength: {
                      value: 20,
                      message: "bruh that's way too long"
                    },
                    pattern: {
                      value: /^[a-zA-Z0-9_]*$/,
                      message: "Username must be alphanumeric"
                    },
                    validate: debouncePromise(async (value) => {
                      return await checkname(value) || "Username taken";
                    }, 250),

                  })}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  variant="outlined"

                  fullWidth
                  id="email"
                  label="Email"
                  name="email"
                  autoComplete="email"
                  error={errors.email !== undefined}
                  helperText={errors.email?.message}
                  inputRef={register({
                    required: 'Required',
                    validate: {
                      valid: value => isEmail(value) || "hey that's not an email",
                    }
                  })}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  helperText={errors.password === undefined ? passwordHelperText : errors.password.message}
                  error={errors.password !== undefined}
                  type={showPassword ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          size="small"
                        >
                          {showPassword ? <Visibility style={{fontSize: '1.2em'}} /> : <VisibilityOff style={{fontSize: '1.2em'}} />}
                        </IconButton>
                      </InputAdornment>
                    )
                  }}
                  inputRef={register({
                    required: 'Required',
                    minLength: {
                      value: 8,
                      message: 'Password too short'
                    },
                    maxLength: {
                      value: 50,
                      message: "bruh"
                    },
                    // pattern: {
                    //   value: /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~].*[0-9]|[0-9].*[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/, //lol
                    //   message: 'Password must contain one special character and one number'
                    // }
                  })}
                />
              </Grid>
              
              <Grid item xs={12}>
                <FormControlLabel
                  control={<Checkbox value="privacypolicy" color="primary" />}
                  label={PrivacyPolicyLabel}
                  className={classes.PrivacyPolicyLabel}
                  name="privacyCheckbox"
                  inputRef={register({
                    validate: {
                      valid: value => value === 'privacypolicy' || "Required",
                    }
                  })}
                />
                <FormHelperText error>{errors.privacyCheckbox?.message}</FormHelperText>
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={<Checkbox value="allowEmails" color="primary" />}
                  label="Send me occasional updates on new models + features"
                  name="getUpdateEmails"
                  inputRef={register}
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Create Account
          </Button>
            <Grid container style={{justifyContent:"flex-end"}}>
              <Grid item>
                <Link href="/login" variant="body2" color="textSecondary">
                  Already have an account? Sign in
              </Link>
              </Grid>
            </Grid>
          </form>
          {/*
          <Typography component="h4" variant="h5">
            OR
        </Typography>
          */}
        </div>
        {
          <Box mt={3}>
            <Typography
              variant="body2" color="textSecondary" align="center"
            >
              This site is protected from robots by reCAPTCHAv3, meaning the <MLink href="https://policies.google.com/privacy" target="_blank" rel="noreferrer" color="textSecondary">Google Privacy Policy</MLink> and <MLink color="textSecondary" href="https://policies.google.com/terms" target="_blank" rel="noreferrer"> Terms of Service</MLink> also apply.</Typography>
          </Box>
        }
      </Container>
      <SimplePopup
        handleClose={handleDialogClose}
        open={popupIsOpen}
        closeText="cool"
        title="What's with the password rules?"
        body={popupBody}
      />
      <Snackbar
        message={snackbarMessage}
        status={snackbarStatus}
        open={snackbarOpen}
        setOpen={setSnackbarOpen}
      />
    </React.Fragment>
  );
}

SignUp.getInitialProps = async ({ req, res, match, history, location, ...ctx }) => {
  //const isLoggedIn = req !== undefined ? ctx.authed : authed;  //if SSR, use authed from context, otherwise use local var;
  //if (isLoggedIn) return { redirectTo: '/' };

  return {darkMode: ctx.darkMode};
}

export default SignUp;