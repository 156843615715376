//https://stackoverflow.com/a/27664971/6293721
function mergeDedupe(lists: Object[][], key: Function) {
    var merged = [].concat.apply([], lists);

    var seen = {};
    return merged.filter(function (item) {
        var k = key(item);
        return seen.hasOwnProperty(k) ? false : (seen[k] = true);
    })
}

function sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

function getRandomInt(max: number) {
    return Math.floor(Math.random() * max);
}

interface redisCache {
    get: Function;
}

//send a JSON string (usually straight from redis)
async function sendString(jsonString: string, res: any) {
    res.setHeader('Content-Type', 'application/json');
    res.end(jsonString);
}

//normaise a cache query string by setting to lower and removing s
function normaliseQ(query: string) {
    if (query.endsWith('s')) query = query.substring(0, query.length - 1);
    return query.toLowerCase();
}
import { Catagories, Licences } from '../util/HardCodedLists';

function buildFilterQuery(qs) {
    let query = { Visibility: 'Public' };
    if (qs.cat && qs.cat < Catagories.length && qs.cat >= 0) query = { ...query, Category: Catagories[qs.cat] }
    if (qs.lic && qs.lic < Licences.length && qs.lic >= 0) query = { ...query, Licence: Licences[qs.lic] }
    if (qs.anim) query = { ...query, Animated: true }

    return query;
}

function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
}

function containsObject(obj, list) {
    var i;
    for (i = 0; i < list.length; i++) {
        if (list[i] === obj) {
            return true;
        }
    }

    return false;
}

function parseCookies(cookies : string ){
    return cookies.split('; ').reduce((prev, current) => {
        const [name, ...value] = current.split('=');
        prev[name] = value.join('=');
        return prev;
      }, {});
}


export { mergeDedupe, sleep, getRandomInt, sendString, normaliseQ, shuffleArray, containsObject, parseCookies, buildFilterQuery}