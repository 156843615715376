import React, { Fragment } from 'react';
import axios from 'axios';
import CssBaseline from '@material-ui/core/CssBaseline';
import PrimarySearchAppBar from '../components/Appbar'
import Helmet from 'react-helmet';
import { Container, Grid, IconButton, makeStyles, Paper, Tooltip, Typography } from '@material-ui/core';
import ML from '@material-ui/core/Link';
import { Link } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { modelCardProps } from '../interfaces/modelcard';

interface propTypes {
    list: { Models: modelCardProps[]; Name: string; Owner: { username: string; }; }; models: any[]; isLoggedIn: boolean; history: any; isLoading: boolean; listID: string;
}

const CCBYlinkAtt = (model) => <>
    "<ML color="primary" component={Link} to={`/m/${model.PublicID}`}>{model.Title}</ML>" by "<ML color="primary" component={Link} to={`/u/${encodeURIComponent(model.Creator.Username)}`}>{model.Creator.Username}"</ML> <br></br>
    is licenced under <ML color="primary" target="_blank" href={`https://creativecommons.org/${model.Licence === 'CC-BY 3.0' ? 'licenses/by/3.0/' : 'publicdomain/zero/1.0/'}`}>{model.Licence}</ML>
</>

const useStyles = makeStyles((theme) => ({
    cardimg: {
        borderRadius: '4px',
        width: '100%'
    },
    attText: {
        width: '100%',
        textAlign: 'center',
        marginTop: theme.spacing(1),
    }
}));

const listCredit = (props: propTypes) => {
    const classes = useStyles();
    return (
        <React.Fragment>
            <CssBaseline />

            <Helmet><title>{`${props.list.Name} Credits - Poly Pizza`}</title></Helmet>
            <PrimarySearchAppBar loggedIn={props.isLoggedIn} history={props.history} isLoading={props.isLoading} />

            <Container maxWidth="xl" style={{ paddingTop: '5vh' }}>
                <div style={{ display: 'flex', alignItems: 'center', paddingBottom: '1vh' }}>

                    <Tooltip title="Back to list" placement="top">
                        <IconButton aria-label="Delete all models" onClick={() => props.history.push('/l/' + props.listID)} >
                            <ArrowBackIcon />
                        </IconButton>
                    </Tooltip>

                    <Typography variant="h4">{props.list.Name} Credits</Typography>
                </div>

                {/* Image grid */}
                <Grid item container spacing={3} direction="row">
                    {props.models.map((m) => {
                        return (
                            <Grid item container direction="column" key={m.ResourceID} xs={12} sm={6} md={4} lg={3}>
                                <Grid item>
                                    <Link to={`/m/${m.PublicID}`}>
                                        <img src={`https://static.poly.pizza/${m.ResourceID}.webp`} className={classes.cardimg} />
                                    </Link>
                                </Grid>

                                <Grid item className={classes.attText}>
                                    <Typography variant="body1">{CCBYlinkAtt(m)}</Typography>
                                </Grid>
                            </Grid>
                        )
                    })}
                </Grid>
            </Container>
        </React.Fragment>
    );
}


listCredit.getInitialProps = async function ({ req, res, match, history, location, ...ctx }) {
    let listResp;
    try {
        listResp = await axios.get(`${process.env.SITE_ROOT}/api/list/${match.params.id}`);
    } catch (e) {
        return { statusCode: e.response.status };
    }

    //Get all model deets
    let promises = [];
    let models = new Array(listResp.data.Models.length);
    for (let i = 0; i < listResp.data.Models.length; i++) {
        const m = listResp.data.Models[i];
        promises.push(
            axios
                .get(`${process.env.SITE_ROOT}/api/model/${m.publicID}/details`)
                .then((d) => models[i] = d.data)
        );
    }

    await Promise.allSettled(promises);

    return { isLoggedIn: ctx.authed, list: listResp.data, listID: match.params.id, darkMode: ctx.darkMode, models }
}

export default listCredit;