import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ListCard from './ListCard';
import { Badge, Grid, IconButton, Typography } from '@material-ui/core';
import axios from 'axios';
import update from 'react-addons-update';
import LaunchIcon from '@material-ui/icons/Launch';
import { Link } from 'react-router-dom';
import QuickCreateList from './QuickCreateList';

const useStyles = makeStyles((theme) => ({
    listCard: { pointerEvents: 'none', userSelect: 'none', "& hover": { transform: 'scale(1.05)' } }
}));

export default function ListSelectDialog(props: propTypes) {
    const classes = useStyles();

    const [loaded, setloaded] = useState(false);
    const [myLists, setLists] = useState([]);
    const [createListOpen, setcreateListOpen] = useState(false);

    const handleClose = () => {
        props.setOpen(false);
    };

    function refreshLists() {
        axios.get('/api/me/lists').then((d) => {
            setloaded(true);
            setLists(d.data);
        });
    }

    useEffect(() => {
        if (!props.open || loaded) return; //call api on opened
        refreshLists();
    }, [props.open]);

    useEffect(() => {
        setloaded(false);
    }, [props.model.PublicID]);

    async function AddToList(i: number) {
        let newList;
        const query = {};
        const index = myLists[i].Models.findIndex(o => o.PublicID === props.model.PublicID)
        if (index !== -1) { //Already in list so remove it
            myLists[i].Models.splice(index, 1);
            query[i] = { Models: { $set: [...myLists[i].Models] } };
        }
        else {
            query[i] = { Models: { $set: [{ PublicID: props.model.PublicID, S3ID: props.model.ResourceID }, ...myLists[i].Models] } };
        }
        newList = update(myLists, query);
        setLists(newList);

        //strip public ids for upload
        let pids = []
        newList[i].Models.map((m) => pids.push(m.PublicID));
        
        await axios.post(`/api/list/${myLists[i].PublicID}`, { Models: pids }, { withCredentials: true });

        /* @ts-ignore*/
        window?.plausible("Added To List", { props: { list: myLists[i].PublicID} });
    }

    const Listview = () => (
        <Grid container spacing={4} style={{ marginBottom: '20px' }}>
            {myLists.map((list, i) => {
                const inList = list.Models.findIndex(o => o.PublicID === props.model.PublicID) !== -1;
                return <Grid item key={list.PublicID + ' ' + list.Models.length} xs={12} sm={6} md={4} lg={3}>
                    <Badge badgeContent={'✔'} invisible={!inList} color="primary" anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} className={'listSelectAnim'}>
                        <div onClick={() => AddToList(i)}>
                            <ListCard list={list} noAvatar className='pointer-events-none select-none' />
                        </div>
                        <IconButton style={{ position: 'absolute', right: '3px', top: '3px' }} component={Link} to={"/l/" + list.PublicID} ><LaunchIcon /></IconButton>
                    </Badge>
                </Grid>
            })}

            { /*Show a lil shrug guy when the user has no models */
                myLists.length == 0 &&
                (<Grid item xs={12} style={{ textAlign: 'center' }}>
                    <Typography variant="h1">¯\_(ツ)_/¯</Typography>
                    <Typography variant="h4">
                        You don't have any lists! <br />
                        Click add to make one
                    </Typography>
                </Grid>)
            }
        </Grid>
    );

    return (
        <Dialog
            fullWidth={true}
            maxWidth={"lg"}
            open={props.open}
            onClose={handleClose}
        >
            <DialogTitle style={{ pointerEvents: 'none', userSelect: 'none' }}>
                Add "{props.model.Title}" to a List
            </DialogTitle>
            <DialogContent>
                {!loaded ? <p>loading...</p> : <Listview />}
                {createListOpen && <QuickCreateList OnCreated={refreshLists}/>}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setcreateListOpen(true)} color="primary">
                    Create new list
                </Button>
                
                <Button onClick={handleClose}>
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
}

interface propTypes {
    setOpen: (arg0: boolean) => void;
    open: boolean;
    model: any;
}