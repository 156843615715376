import { Typography, Paper, Chip, Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid } from '@material-ui/core';
import { Link as MUILink } from '@material-ui/core';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ListCard as ListCardData } from '../types/List';
import ListCard from './ListCard';

interface propTypes {
    className?: string;
    bundles: ListCardData[];
}
export default function smolListCard(props: propTypes) {

    const bundles = props.bundles;
    if (!bundles || bundles.length == 0 || !bundles[0]) return null;

    const first = props.bundles[0];

    const [modalOpen, setmodalOpen] = useState(false);

    function getSrc() {
        if (first.Images.length > 0)
            return `https://static.poly.pizza/${first.Thumbnail}`;
        else if (first.Models.length > 0)
            return `https://static.poly.pizza/${first.Models[0].S3ID}.webp`;
        return '/img/placeholder.png'
    }

    // Show featured list first and user lists after
    if (first.Owned === true) 
        return (
            <div className={'flex flex-col gap-2 mt-2 ' + props.className}>
                <Typography variant="h6" className='!font-thin'>Included in:</Typography>
                <Link to={first.URL} className={'!no-underline'}>
                    <Paper className='flex items-center gap-5 pr-7'>
                        <img className='h-20 aspect-square object-cover' src={getSrc()} />
                        <div className='flex flex-col items-start gap-1'>
                            <Typography variant="body1">{first.Name}</Typography>
                            <Chip label={first.Models.length + ' models '} />
                        </div>
                    </Paper>
                </Link>
                {props.bundles.length > 1 && <MUILink onClick={() => setmodalOpen(true)} color="textSecondary" className='text-right'>and {props.bundles.length - 1} more lists</MUILink>}
                <Modal open={modalOpen} setOpen={setmodalOpen} bundles={props.bundles} hideFirst={true} />
            </div>
        )


    // Otherwise show user lists in big box
        return (
            <div className={'flex flex-col gap-2 mt-2 ' + props.className}>
                <Typography variant="h6" className='!font-thin'>Included in:</Typography>
                <div onClick={() => setmodalOpen(true)} className={'!no-underline hover:cursor-pointer'}>
                    <Paper className='flex items-center gap-5 pr-7'>
                        <img className='h-20 aspect-square object-cover' src={getSrc()} />
                        <div className='flex flex-col items-start gap-1'>
                            <Typography variant="body1">{props.bundles.length} User lists</Typography>
                        </div>
                    </Paper>
                </div>
                <Modal open={modalOpen} setOpen={setmodalOpen} bundles={props.bundles} hideFirst={false} />
            </div>
        )
}

function Modal(props: { setOpen: (arg0: boolean) => void; open: boolean; bundles: ListCardData[]; hideFirst?: boolean; }) {
    const handleClose = () => {
        props.setOpen(false);
    };

    return (
        <Dialog
            fullWidth={true}
            maxWidth={"lg"}
            open={props.open}
            onClose={handleClose}
        >
            <DialogTitle>
                Lists using this model
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={4}>
                    {
                        // Map bundles except the first one (which is already shown)
                        props.bundles.slice(props.hideFirst ? 1 : 0).map((bundle, i) => {
                            return (
                                <Grid item key={bundle.PublicID} xs={12} sm={6} md={4} lg={3}>
                                    <ListCard list={bundle} />
                                </Grid>
                            )
                        })
                    }
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Close</Button>
            </DialogActions>
        </Dialog>
    );
}