import React from 'react';
import CardMedia from '@material-ui/core/CardMedia';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import MUILink from '@material-ui/core/Link';
import Skeleton from '@material-ui/lab/Skeleton';
import { Chip } from '@material-ui/core';
import { ListCard as ListCardData } from '../types/List';
import GetBundleURL from '../util/GetBundleURL';
import ModelCard from './ModelCard2';

const useStyles = makeStyles((theme) => ({
    cardMedia: {
        paddingTop: '59%', // 56.25% = 16:9
    },
    card: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    cardHeader: {
        padding: '12px',
    },
    avatar: {
        // backgroundColor: '#33b3e3',
    },
    cardHeaderTitle: {
        fontWeight: 500,
    },
    subheader: {
        color: theme.palette.grey[500],
    }
}));

interface propTypes {
    list: ListCardData;
    noAvatar?: boolean;
    style?: any;
    className?: any;
}


export default function ListCard(props: propTypes) {
    
    // Show skeleton if list isn't loaded. Pretty insane this works
    if(!props.list) return <ModelCard skeleton {...props} />; 
    
    const classes = useStyles();
    const URL = props.list.URL;

    function getSrc(index: number) {
        if (index < props.list.Models.length)
            return `https://static.poly.pizza/${props.list.Models[index].S3ID}.webp`;
        else
            return '/img/placeholder.png'
    }

    return (
        <Card className={`${classes.card} ${props.className}`} style={props.style}>
            <Link to={URL}>
                {/* @ts-ignore*/}
                <CardMedia
                    title={props.list.Name}
                    loading="lazy"
                    alt={props.list.Name}
                    >
                    {props.list.Images.length > 0 ? //bundles do not nessicarily have images
                    // featured image if list is a bundle
                        <img
                            src={`https://static.poly.pizza/${props.list.Thumbnail}`}
                            alt={`Thumbnail for ${props.list.Name}`}
                            className="w-full anim-fadein aspect-[687/403] object-cover"
                        /> :
                        
                        // Otherwise split 4 view of models in list 
                        <div className='flex flex-wrap'>
                            {
                                [...Array(4).keys()].map((x, i) => {
                                    const m = props.list.Models[i];
                                    return <img key={m ? m.S3ID : i} src={getSrc(i)} className="w-1/2"/>
                                })
                            }
                        </div>
                    }
                </CardMedia>
            </Link>

            <CardHeader
                classes={{
                    root: classes.cardHeader,
                    title: classes.cardHeaderTitle,
                }}
                avatar={props.noAvatar ? <div /> : <Avatar aria-label="dp" src={props.list.Owner.DPURL} className={classes.avatar}>{props.list.Owner.Initials}</Avatar>}
                title={<MUILink color="textPrimary" component={Link} to={URL}>{props.list.Name}</MUILink>}
                subheader={<MUILink className={classes.subheader} to={`/u/${props.list.Owner.Username}`} component={Link}>{props.list.Owner.Username}</MUILink>}
                action={
                    <div style={{ display: 'flex', marginTop: '12px', marginRight: '5px' }}>
                        <Chip label={props.list.Models.length + ' models '} />
                    </div>
                }
            />
        </Card>
    )
}
