import React, { useState } from 'react';
import CardMedia from '@material-ui/core/CardMedia';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import MUILink from '@material-ui/core/Link';
import Skeleton from '@material-ui/lab/Skeleton';
import Paper from '@material-ui/core/Paper'
import { Typography } from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import { LocationDescriptor, Location } from 'history';
import { modelCardProps } from '../types/modelcard';

interface propTypes {
    index: number;  //Used for nice animation offset while loading
    model?: modelCardProps; //card data, if null will render a skeleton
    noLink?: boolean; //if true, ignore link click behaviour
    onClick?: React.MouseEventHandler<HTMLAnchorElement> & React.MouseEventHandler<HTMLSpanElement>; //custom click behaviour
    showLicence?: boolean; //Show licence
    noAvatar?: boolean;  //Don't show avatar
    className?: string; //Custom class
    style? : React.CSSProperties; //Custom style
}

export default function ModelCard(props: propTypes) {

    const imgSkeleton = <Skeleton className='pt-[59%] w-full' style={{ paddingTop: '59%', animationDelay: props.index * 0.1 + 's' }} variant="rect" height={100} />

    // Full image and text skeleton for when we don't know the model
    if (!props.model) {
        return (
            <Card className={'!h-full !flex !flex-col'} >
                <CardMedia>
                    {imgSkeleton}
                </CardMedia>
                <CardHeader
                    title={<Skeleton animation="wave" height={10} width="80%" style={{ marginBottom: 6 }} />}
                    subheader={<Skeleton animation="wave" height={10} width="40%" />}
                    classes={{
                        root: 'p-4',
                        title: 'font-medium',
                    }}
                    avatar={
                        props.noAvatar ?
                            <div className='h-[40px]' style={{ flex: '0 0 auto' }}></div> :
                            <Skeleton animation="wave" width={40} height={40} variant='circle' />
                    }
                />
            </Card>
        )
    }

    const [loading, setLoading] = React.useState(true);
    const [imagesrc, setImageSRC] = React.useState(props?.model?.previewUrl);
    const onImageError = () => { setImageSRC('/img/placeholder.png') }
    const imgEl = React.useRef<HTMLImageElement>();

    const imageLoaded = () => {
        setLoading(false);
        imgEl.current.removeAttribute('onLoad');
        imgEl.current.removeAttribute('onError');
    }

    React.useEffect(() => {
        if (imgEl.current.complete) imageLoaded();
    }, [imgEl.current]);

    const CardThumb =
        <CardMedia>
            <div>
                {loading && imgSkeleton}
                <img
                    ref={imgEl}
                    onLoad={imageLoaded}
                    onClick={props.onClick}
                    // onAuxClick={window.open(props.model.url,'_blank').focus()}
                    onError={onImageError}
                    src={imagesrc}
                    className={`w-full anim-fadein ${loading ? 'hidden' : ''}`}
                    alt={props.model?.title}
                />
            </div>
        </CardMedia>

    return (
        // h-auto remove the chin effect when there's other shit in the grid but fucks up show license so we switch it  
        <Card className={`flex flex-col ${props.showLicence ? "h-full" : "h-auto"} ${props.className}`} style={props.style}>
            {/* Image (use link normally, onclick for anything else)*/}
            {
                props.noLink ?
                    <div className='hover:cursor-pointer'>{CardThumb}</div> :
                    <Link to={props.model.url}>{CardThumb}</Link>
            }

            <CardHeader
                classes={{ root: '!p-3 !mt-[-5px]', title: '!font-medium', action: 'h-full !m-0' }}
                avatar={
                    props.noAvatar ? <div></div> : <Avatar aria-label="dp" src={props.model.creator.DPURL}>{props.model.creator.initials}</Avatar>
                }
                title={
                    <MUILink
                        color="textPrimary"
                        className='hover:cursor-pointer'
                        component={!props.noLink && Link}
                        to={props.model.url}
                        onClick={props.onClick}
                        onAuxClick={props.onClick}
                    >
                        {props.model.title}
                    </MUILink>
                }
                subheader={
                    <MUILink className={'!text-gray-light'} to={props.model.creator.profileLink} component={Link}>{props.model.creator.username}</MUILink>
                }
                action={
                    props.showLicence && <div className="flex flex-col items-end justify-center h-full gap-1">
                        <Chip label={props.model.licence.split(' ')[0]} className="!text-[#C1C1C1] !font-mono uppercase" size="small" />
                    </div>
                }
            />
        </Card>

    )
}